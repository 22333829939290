import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/community.module.css'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c3" }) {
      headline
      byline
      state {
        childImageSharp {
          thumb: gatsbyImageData(width: 300 height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const Community = () => {
  const { content } = useStaticQuery(essayQuery)
  return (
    <Layout>
      <section>
        <h1>{content.headline}</h1>
          <h2>About Seaside</h2>
            <p>
              Seaside is a small coastal community located on the Florida
              Panhandle, in between Panama City to the east and Fort Walton
              Beach to the west, in the city of Santa Rosa Beach and the county
              of Ft. Walton. The area is quite diverse geographically comprised
              of beautiful beaches on the Gulf of Mexico, the Choctawhatchee Bay
              a bit inland, forested state parks, and multiple streams, ponds,
              and small lakes. Much of the land surrounding the county was owned
              by the St. Joe Paper Company.
            </p>
            <div className={styles.commImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.state.childImageSharp]}
              />
              Seaside, FL
            </div>
            <p>
              Seaside is heralded as the first &quot;New Urban&quot; community.
              Founded in 1979 (construction began in 1981) by Robert Davis and
              planned by Andres Duany and Elizabeth Plater-Zyberk in
              consultation with Leon Krier, Seaside is an 80 acre community with
              a population of approximately 1228, including cats and dogs. New
              Urbanism is an argument against suburban sprawl and the
              re-awakening of the American city. Its principles state that a
              walkable, connected, mixed use community will yield a better
              quality of life. Seaside is the first of many successful New
              Urbanist communities in the US and abroad.
            </p>
            <p>
              Robert Davis inherited the land that Seaside sits on from his
              grandfather J. Smolian.
              <sup>
                <a id='fnref:1' href='#fn:1'>
                  1
                </a>
              </sup>{' '}
              His intention with the land was to build a utopian city that would
              draw Americans tired of suburban living back to small city life.
              Seaside was created as a resort community. The vast majority of
              properties are individually owned but are part of large rental
              programs. As of December 2012 there were only 17 permanent
              residents but the Seaside homeowners are very involved with the
              community&apos;s management.
            </p>
            <div>
              <p>
                Seaside and its founder are the recipients of many notable
                awards in architecture and planning including the following:
              </p>
              <ul className={styles.nobullets}>
                <li>
                  1983 - Award of Excellence from the South Florida Chapter of
                  the AIA
                </li>
                <li>1984 - Award of Excellence from the Florida AIA</li>
                <li>1984 - Citation of Merit from Progressive Architecture</li>
                <li>1986 - Florida Governor&apos;s Design Award</li>
                <li>1986 - Builder Magazine&apos;s Grand Award</li>
                <li>
                  1988 - AIA National Citation Award for Excellence in Urban
                  Design
                </li>
                <li>1988 - Southwest Builder&apos;s Conference Grand Award</li>
                <li>
                  1988 - Florida AIA Citation for Excellence in Urban Design
                </li>
                <li>1989 - Southern Living Home Award</li>
                <li>1989 - Progressive Architecture Award</li>
                <li>
                  1990 - &quot;Designs of a Decade&quot; from Time Magazine
                </li>
                <li>
                  1990 - Four awards at the National AIA Honors Convention
                </li>
                <li>2003 - Urban Land Institute Award for Excellence</li>
              </ul>
            </div>
        </section>
        <section>
          <h2>Seaside and Notre Dame</h2>
            <p>
              The University of Notre Dame established a relationship with the
              community of Seaside in 2010 through its founder Robert Davis and
              his long association with ND&apos;s School of Architecture. Mr.
              Davis serves as a juror for the{' '}
              <SmartLink to='http://architecture.nd.edu/about/driehaus-prize/'>
                Richard H. Driehaus Prize
              </SmartLink>{' '}
              at the University of Notre Dame, a yearly prize awarded to an
              architect practicing in the classical tradition. This relationship
              led Mr. Davis to seek assistance from the School and the Hesburgh
              Libraries of Notre Dame&apos;s Architecture Library for the
              storage and organization of the Seaside archives.
            </p>
        </section>
        <section>
          <h2>The Seaside Archives</h2>
            <div>
              <p>
                The Seaside Archives presented many unique opportunities and
                challenges for Notre Dame. It opened up the occasion for
                collaboration between the School of Architecture and the
                Hesburgh Libraries. While they had a long history of working
                together, they had not been presented with an opportunity for a
                formal arrangement.
              </p>
              <p>
                The archives themselves were problematic. They were made up of
                several different types of materials and content relating to the
                history and building of Seaside. The materials associated with
                the history of Seaside, including letters, images, brochures,
                videos, etc., were the intellectual property of Seaside.
                However, the vast majority of the archives were plans and
                building content for the 372 structures in Seaside. While these
                items were owned physically by Seaside the copyright was held by
                the architect or designer of the building. This presented
                challenges as to what was permissible with the content.
              </p>
              <p>
                Another unique challenge with the Seaside archives was that they
                were housed in four different locations. With one exception,
                these locations were random and the materials had been
                reorganized and relocated many times. This altered the original
                plan of providing organization and access to materials in a
                traditional archival method.
              </p>
            </div>
        </section>
        <section>
          <h2>Building the Seaside Research Portal</h2>
            <div>
              <p>
                The Seaside Research Portal was developed for two primary
                reasons. The first was that Notre Dame needed a way to provide
                access to the archival and architectural materials prior to the
                having a physical space to store and present them. The second
                was that Notre Dame wanted to explore new options for presenting
                the built environment online. With Seaside there was a unique
                opportunity to develop a tool that would allow one to study the
                architecture and urbanism of Seaside from the urban scale down
                to the individual building level. Seaside is a small enough
                community that this could be accomplished in a reasonable amount
                of time.
              </p>
              <p>
                The first challenge, to trace the urban development of Seaside,
                turned out to be quite simple. The Seaside Archives contained
                some early plans and held references to many others. These plans
                had one thing in common — the firm of Duany Plater-Zyberk and
                Company. Principals Andres Duany and Elizabeth Plater-Zyberk
                graciously shared their archival materials relating the plan of
                Seaside. In addition, Andres Duany provided descriptions and
                commentary for each phase of the community&apos;s development.
              </p>
              <p>
                While researching and collecting information at the DPZ main
                office in Miami, Florida it was noted that each plan of Seaside,
                from the first Arquitectonica plan in 1979 to the final plans in
                the mid-1980s, was done on transparent paper. When stacked atop
                one another it was possible to see how the plan developed. In
                order to replicate this virtually the ‘Explore the Plan&apos;
                section of the Portal was created. To achieve this the
                Portal&apos;s front-end developer, Dan Brubaker Horst, used
                JavaScript to create an interactive visualization that varies
                the opacity of these plans. In this way it facilitates the
                juxtaposition of different phases in Seaside&apos;s planning and
                development.
              </p>
              <p>
                To further explore the urban plan of Seaside the interactive map
                was developed. It combines information from the Google Maps API
                and the catalog of the Seaside Research Portal. The map allows
                the user to place the building they are researching in its
                geographic location. In addition, one can search the interactive
                map by architect or keyword. The results provided include
                geographic markers and links to building names and building
                records.
              </p>
            </div>
        </section>
        <section>
          <h2>Developing the Content</h2>
            <div>
              <p>
                The Seaside Research Portal&apos;s Content Development team
                works closely with architects, photographers, and Seaside
                homeowners to gather, digitize, describe, and display the
                content in the Portal.
              </p>
            </div>
        </section>
        <section>
          <div>
            <hr className={styles.footnoteHr} />
          </div>
          <ol className={styles.commNote}>
            <li>
              For more information on J. Smolian see{' '}
              <SmartLink to='/essays/visions-of-seaside'>
                Visions of Seaside
              </SmartLink>
              &nbsp;
              <a id='fn:1' href='#fnref:1'>
                ↩
              </a>
            </li>
          </ol>
      </section>
    </Layout>
  )
}

export default Community
